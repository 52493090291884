import React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { Container, Row } from "react-bootstrap"
import Layout from "../components/Layout"
import BlogCard from "../components/BlogCard"

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allBloggerPost.edges
    const postCards = posts.map(({ node: post }) => (
      <BlogCard key={post.id} post={post} />
    ))

    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allBloggerPost.totalCount
    const tagHeader = `${totalCount} postage${
      totalCount === 1 ? "m" : "ns"
    } marcada${totalCount === 1 ? "" : "s"} com a tag “${tag}”:`

    return (
      <Layout>
        <Helmet title={`${tag} | ${title}`} />
        <Container>
          <h3 className="mt-3 cl-vinho">{tagHeader}</h3>
          <Row>{postCards}</Row>
          <p>
            <Link
              to="/tags/"
              className="text-decoration-none font-weight-bold cl-marinho hovcl-vinho"
            >
              Mostrar todas as tags
            </Link>
          </p>
        </Container>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allBloggerPost(
      limit: 1000
      sort: { fields: published, order: DESC }
      filter: { labels: { in: [$tag] } }
    ) {
      totalCount
      edges {
        node {
          id
          slug
          title
          published(formatString: "dddd, DD [de] MMMM [de] YYYY", locale: "pt")
          labels
          featuredImage {
            childImageSharp {
              fluid(
                maxWidth: 1200
                maxHeight: 800
                fit: COVER
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
